.hu_block6_2 {
    width: 1166px;
}

.hu_block6_tg2 {
    position: relative;

    width: 832px;
    padding: 60px;
    box-sizing: border-box;
}

.hu_block6_tg2::before {
    position: absolute;
    top: 27px;
    left: 3px;

    content: url('../../../icons/quotes.svg');
}

.hu_block6_tg2::after {
    position: absolute;
    bottom: 49px;
    right: 0;

    transform: rotate(0.5turn);
    content: url('../../../icons/quotes.svg');
}

.hu_block6_tg3 {
    padding-right: 336px;

    text-align: right;
}

@media (max-width: 1024px) {
    .hu_block6_2 {
        width: 100%;
        padding: 0 20px;
    }

    .hu_block6_tg2 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .hu_block6_tg1 {
        font-size: 32px;
    }

    .hu_block6_tg2 {
        font-size: 22px;
        padding: 40px;
    }

    .hu_block6_tg3 {
        align-self: flex-end;

        width: 70%;
        padding: 0;
    }
}