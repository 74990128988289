.block4_2 {
    width: 1087px;
}

.block4_3 {
    row-gap: 102px;

    width: 1037px
}

.block4_6 {
    width: 530px;
}

@media (max-width: 1024px) {
    .block4_1 {
        width: 100%;
        padding: 0 70px 0 20px;
    }

    .block4_2 {
        width: auto;
    }

    .block4_3 {
        row-gap: 60px;

        width: auto;
    }
}

@media (max-width: 720px) {
    .block4_1 {
        padding: 0 20px;
    }

    .block4_4 {
        flex-direction: column;
    }

    .block4_6 {
        width: 100%;
        margin-top: 40px;
    }

    .block4_7 {
        padding-left: 20px;
    }

    .block4_image {
        width: inherit;
    }
}