.block7E_2 {
    width: 1166px;
}

.block7E_3_wrap {
    width: 818px;
}


@media (max-width: 1024px) {
    .block7E_2 {
        width: 100%;
        padding: 0 20px;
    }

    .block7E_3_wrap {
        width: 80%;
    }
}

@media (max-width: 720px) {
    .block7E_tg1 {
        font-size: 32px;
    }

    .block7E_tg2 {
        letter-spacing: -2px;
    }
}