.first_bg {
    position: relative;
    overflow: hidden;
}

.first_bg::after {
    position: absolute;
    top: 0;
    left: 0;

    content: url('../../images/hu_block1_bg.svg');
    
    transform: scale(1.2);
}

.hu_block7_2 {
    width: 1166px;
}

@media (max-width: 1024px) {
    .first_bg::after {
        display: none;
        transform: none;
    }

    .hu_block7_tg1 {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 720px) {
    .header {
      justify-content: flex-start;

      padding: 0 !important;
    }

    .hu_block7_tg1 {
        width: 100%;
        padding: 0 20px;

        font-size: 32px;
    }
}