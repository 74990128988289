.hu_block1_1 {
    width: 100%;
    height: calc(100vh - 220px);
}

.hu_block1_2 {
    width: 1166px;

    text-align: center;
}

.hu_block1_button {
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 303px;
    height: 53px;
    margin: 0 auto;

    color: var(--black-1-color);
    text-decoration: none;

    background-image: var(--button-default-color);
    border-radius: 45px;
}

.hu_block1_button:hover {
    background-image: var(--button-hover-color);
}

@media (max-width: 1024px) {
    .hu_block1_1 {
        height: auto;
    }

    .hu_block1_2 {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 720px) {
    .hu_block1_tg1 {
        font-size: 40px;
    }

    .hu_block1_tg2 {
        font-size: 28px;
    }
}