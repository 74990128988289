.block1_2 {
    width: 1087px;
}

.block1_4 {
    z-index: 1;

    padding: 20px 30px;

    border-radius: 40px 40px 40px 0px;
    background-color: var(--white-1-color);
    box-shadow: 
        0px 11.99619px 37.1195px 0px rgba(0, 0, 0, 0.07),
        0px 12.85947px 22.8252px 0px rgba(0, 0, 0, 0.04), 
        0px 14.27417px 40.6809px 0px rgba(0, 0, 0, 0.03),
        0px 27px 191px 0px rgba(0, 0, 0, 0.03);
}

.block1_header_button {
    position: relative;
  
    display: none;
    align-items: center;
    justify-content: center;
    align-self: center;
  
    width: 175px;
    height: 41px;
  
    color: var(--white-1-color);
    text-decoration: none;
  
    border-radius: 62px;
    background-color: var(--main-1-color);
}

@media (max-width: 1024px) {
    .block1_1 {
        width: 100%;
    }

    .block1_2 {
        flex-direction: column;

        width: auto;
    }

    .block1_3 {
        text-align: center;
    }
}

@media (max-width: 720px) {
    .block1_1 {
       padding: 0 20px;
    }

    .block1_title {
        font-size: 40px;
        line-height: 1.05; 
    }

    .block1_subtitle {
        font-size: 28px;
        line-height: 1.05;
    }

    .block1_4 {
        padding: 20px;
    }

    .block1_text {
        font-size: 16px;

        white-space: unset;
    }

    .block1_header_button {
        display: flex;
    }
}