.footer {
    z-index: 1;

    justify-content: space-between;

    width: 1166px;
}

.footer_links {
    gap: 30px;
}

.footer_link {
    text-decoration: none;

    color: var(--gray10-1-color);
}

@media (max-width: 1024px) {
    .footer {
        width: 100%;
        padding: 0 100px;
    }
}

@media (max-width: 720px) {
    .footer {
        flex-direction: column-reverse;

        width: 100%;
        padding: 0;
    }

    .footer_tg1 {
        text-align: center;
    }

    .footer_links {
        justify-content: space-between;
        gap: 0;

        margin-bottom: 16px;
    }
}