.block8E_1 {
    position: relative;

    background-image: url('../../../images/backgroundImageYellow.svg');
    
}

.block8E_1::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(180deg, #F7C367 0%, #ED6D1E 100%);
    opacity: 0.5;
}

.block8E_2 {
    position: relative;
    z-index: 1;

    width: 897px;
}

.block8E_button {
    width: 425px;
    height: 55px;
}

@media (max-width: 1024px) {
    .block8E_1 {
        width: 100%;
        padding: 0 20px;
    }

    .block8E_2 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .block8E_2 {
        width: 100%;
        padding: 0 20px;
    }    

    .block8E_tg1 {
        font-size: 32px;
        font-weight: normal;

        letter-spacing: -2px;
    }

    .block8E_button {
        width: 280px;
        height: 84px;
    }

    .block6_2_button_label {
        width: 70%;

        font-size: 16px;
    }
}