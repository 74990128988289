.box {
    --shadow:
        0px 11.99619px 37.1195px 0px rgba(0, 0, 0, 0.07),
        0px 12.85947px 22.8252px 0px rgba(0, 0, 0, 0.04), 
        0px 14.27417px 40.6809px 0px rgba(0, 0, 0, 0.03),
        0px 27px 191px 0px rgba(0, 0, 0, 0.03);

    position: relative;

    display: flex;

    box-sizing: border-box;
}

.boxTheme_withBackgroundColor {
    overflow: hidden;
    
    background-color: var(--background-1-color);
}

.boxTheme_withBackgroundEchoColor {
    overflow: hidden;
    
    background-color: var(--background-echo);
}

.boxTheme_header {
    position: fixed;
    z-index: 100;

    width: 100%;
    padding: 36px 75px;
}

.boxTheme_signature_child_block1 {
    column-gap: 8px;
}

.boxTheme_signature_child_block1::before {
    position: absolute;
    z-index: 0;
    top: 166%;
    right: 100%;
    transform: translate(50%,-50%);

    content: url('../../images/circle.svg');
}

.boxTheme_image_child_block1 {
    overflow: hidden;

    width: 61px;
    height: 61px;

    border-radius: 100%;
    border: 2px solid var(--accent-1-color);
}

.boxTheme_block2,
.boxTheme_block2_echo {
    column-gap: 167px;
}

.boxTheme_child_block2,
.boxTheme_child_block2_echo {
    flex-shrink: 0;

    width: 385px;
    padding: 30px;

    border-radius: 15px;
    background-image: url('../../images/backgroundImageYellow02.svg');
}

.boxTheme_child_block2::before,
.boxTheme_child_block2_echo::before{
    position: absolute;
    top: -8px;
    right: -10px;

    content: url('../../images/border.svg');
}

.boxTheme_child_block2:not(:last-child)::after {
    position: absolute;
    top: calc(50% - 8px);
    right: -135px;

    height: 8px;

    content: url('../../images/joint.svg');
}

.boxTheme_child_block2_echo:not(:last-child)::after {
    position: absolute;
    top: calc(50% - 8px);
    right: -135px;

    height: 8px;

    content: url('../../images/joint_2.svg');
}

.boxTheme_block3 {
    gap: 50px;
}

.boxTheme_block4 {
    row-gap: 102px;
}

.boxTheme_child_block4 {
    overflow: hidden;

    border-radius: 15px;
    background-color: var(--white-1-color);

    box-shadow: var(--shadow);
}

.boxTheme_child_block4_header {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 40px;
    padding-left: 37px;

    background-color: var(--accent-1-color);
}

.boxTheme_parent_block4:nth-child(1) .boxTheme_child_block4 {
    padding: 45px 40px 30px 40px;
}

.boxTheme_parent_block4:nth-child(2) .boxTheme_child_block4 {
    padding: 60px 40px 30px 40px;
}

.boxTheme_parent_block4:nth-child(3) .boxTheme_child_block4 {
    padding: 60px 40px 40px 40px;
}

.boxTheme_parent_block4:nth-child(4) .boxTheme_child_block4 {
    padding: 60px 40px 40px 40px;
}

.boxTheme_parent_block4:nth-child(5) .boxTheme_child_block4 {
    padding: 60px 25px 20px 25px;
}

.boxTheme_parent_block4:nth-child(6) .boxTheme_child_block4 {
    padding: 60px 37px 20px 37px;
}

.boxTheme_block5 {
    row-gap: 80px;
}

.boxTheme_child_block5 {
    column-gap: 107px;
}

.boxTheme_image_child_block5 {
    overflow: hidden;
    height: 224px;

    border-radius: 40px;
    border: 4px solid var(--accent-1-color);
}

.boxTheme_quote::before {
    position: absolute;
    top: -30px;
    left: -58px;

    content: url('../../icons/quotes.svg');
}

.boxTheme_quote::after {
    position: absolute;
    bottom: -7px;
    right: -56px;

    transform: rotate(0.5turn);
    content: url('../../icons/quotes.svg');
}

.boxTheme_block6 {
    background-image: url('../../images/backgroundImageBlue.svg');
}

.boxTheme_child_block6 {
    text-align: center;

    border-radius: 36px;
    border: 5px solid var(--accent-1-color);
    background-color: var(--white-1-color);
}

@media (max-width: 1024px) {
    .boxTheme_block1 {
        flex-direction: column;
        align-items: center;
    }

    .boxTheme_signature_child_block1::before {
        top: 11%;
        right: 70%;
        margin-right: -50%;
    }
}

@media (max-width: 720px) {
    .boxTheme_block2 {
        column-gap: 123px;
    }

    .boxTheme_child_block2 {
        width: 300px;
        height: 143px;
        padding: 30px 20px;
    }

    .boxTheme_child_block2::before {
        right: -2px;

        content: url('../../images/border_mobile.svg');
    }

    .boxTheme_child_block2:not(:last-child)::after {
        right: -112px;
    }

    .boxTheme_quote::before,
    .boxTheme_quote::after {
        display: none;
    }
}