.hu_block2_2 {
    width: 1166px;
}

@media (max-width: 1024px) {
    .hu_block2_2 {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 720px) {
    .hu_block2_tg1 {
        font-size: 32px;
    }

    .hu_block2_tg2 {
        font-size: 24px;
    }
}

