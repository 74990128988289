.block5_2 {
    width: 1087px;
}

.block5_3 {
    width: 1037px;
}

.block5_6 {
    width: 400px;
}

@media (max-width: 1024px) {
    .block5_1 {
        width: 100%;
        padding: 0 20px;
    }

    .block5_2 {
        width: auto;
    }

    .block5_3 {
        width: auto;
    }

    .block5_5 {
        column-gap: 56px;
    }

    .block5_6 {
        flex-shrink: 0;

        width: 330px;
        height: 185px;
    }

    .block5_8 {
        width: 540px;
    }

    .block5_7_text,
    .block5_8_text {
        white-space: unset;
    }
}

@media (max-width: 720px) {
    .block5_3 {
        row-gap: 40px;
    }

    .block5_4_text {
        font-size: 18px;

        white-space: unset;
    }

    .block5_6 {
        display: none;
    }

    .block5_8 {
        width: 100%;
    }
}