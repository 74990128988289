.spacer {
    position: relative;
    z-index: -1;

    height: var(--spacer-desktop-size);
    min-height: var(--spacer-desktop-size);

    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .spacer {
        height: var(--spacer-tablet-size, var(--spacer-desktop-size));
        min-height: var(--spacer-tablet-size, var(--spacer-desktop-size));
    }
}

@media (max-width: 720px) {
    .spacer {
        height: var(--spacer-mobile-size, var(--spacer-desktop-size));
        min-height: var(--spacer-mobile-size, var(--spacer-desktop-size));
    }
}