.block1E_1 {
    width: 100%;
    height: 100vh;

    /* background: linear-gradient(180deg, #F7C367 0%, #F08746 100%); */
    background-image: url('../../../images/block1E_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.block1E_1_wrapper {
    align-items: flex-end;
    justify-content: space-between;

    width: 1166px;
    box-sizing: border-box;
}

.block1E_2 {
    width: 644px;
}

.block1E_2_tg_1 {
    opacity: 0.8;
}

.block1E_2_tg_2 {
    opacity: 0.5;
}

.header_button.block1E_header_button {
    width: 216px;
    height: 54px;

    color: var(--black-1-color);

    background-color: var(--white-1-color);
}

.header_button.block1E_header_button:hover {
    background-color: rgba(250, 250, 250, 0.8);
}

.block1E_video {
    width: 450px;
    height: 477px;

    border-radius: 20px 20px 0 0;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .block1E_1_wrapper {
        width: 100%;
        padding: 0 20px;
    }

    .block1E_2 {
        width: 513px;
    }

    .block1E_2_tg_1 {
        font-size: 40px;
    }

    .block1E_2_tg_2 {
        font-size: 28px;
    }

    .header_button.block1E_header_button:hover {
        background-color: var(--white-1-color);
    }
}

@media (max-width: 720px) {
    .block1E_video {
        display: none;
    }
}