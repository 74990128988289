.hu_block9_2 {
    width: 897px;
    padding: 70px;
}

.hu_block9_tg1 {
    font-weight: bold;
}

@media (max-width: 1024px) {
    .block6_1 {
        width: 100%;
        padding: 0 20px;
    }

    .block6_2 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .hu_block9_2 {
        width: 100%;
        padding: 40px 20px;
    }    

    .hu_block9_tg1 {
        font-size: 32px;
        font-weight: normal;

        letter-spacing: -2px;
    }

    .block6_2_button_label {
        width: 70%;

        font-size: 16px;
    }
}