.block5E_2 {
    width: 1166px;
}

.block5E_2_tg2 {
    position: relative;

    width: 718px;
    padding-left: 60px;
}

.block5E_2_tg2::before {
    position: absolute;
    top: -35px;
    left: 4px;

    content: url('../../../icons/quotes.svg');
}

.block5E_2_tg2::after {
    position: absolute;
    bottom: -12px;
    right: -54px;

    transform: rotate(0.5turn);
    content: url('../../../icons/quotes.svg');
}

.block5E_2_tg3 {
    padding-left: 226px;
}

@media (max-width: 1024px) {
    .block5E_2 {
        width: 100%;
        padding: 0 20px;
    }

    .block5E_2_tg3 {
        padding: 0;

        text-align: right;
    }
}

@media (max-width: 720px) {
    .block5E_2_tg1 {
        font-size: 32px;
    }

    .block5E_2_tg2 {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
    }
}