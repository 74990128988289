.block2E_2 {
    width: 1166px;
    box-sizing: border-box;
}

.block2E_2_tg2 {
    width: 1037px;

    line-height: 1.6;
}

@media (max-width: 1024px) {
    .block2E_2 {
        width: 100%;
        padding: 0 20px;
    }

    .block2E_2_tg2 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .block2E_2_tg1 {
        font-size: 32px;
    }

    .block2E_2_tg2 {
        font-size: 24px;

        white-space: pre-wrap;
    }
}