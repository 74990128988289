.block6_2 {
    width: 897px;
}

@media (max-width: 1024px) {
    .block6_1 {
        width: 100%;
        padding: 0 20px;
    }

    .block6_2 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .block6_2_button_label {
        font-size: 18px;
    }
}