.hu_block3_2 {
    width: 1166px;
}

.hu_block3_3 {
    position: relative;

    width: 905px;
    height: auto;
    padding: 80px;
    row-gap: 70px;
    column-gap: 10px;

    background-color: var(--white-1-color);
    border-radius: 15px;
}

.hu_block3_3::after {
    content: url('../../../images/hu_block3_border_desk.svg');
    position: absolute;
    top: -15px;
    left: -17px;
}

.hu_block3_tg2,
.hu_block3_tg3 {
    width: 367px;
}

@media (max-width: 1024px) {
    .hu_block3_2 {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 720px) {
    .hu_block3_tg1 {
        font-size: 32px;
    }

    .hu_block3_tg2,
    .hu_block3_tg3 {
        width: 100%;
    }

    .hu_block3_3 {
        width: 100%;
        padding: 40px 20px;
    }

    .hu_block3_3::after {
        display: none;
    }

    .hu_block3_4 {
        flex-direction: column;
    }
}

