.block4E_2 {
    width: 1166px;
    box-sizing: border-box;
}

.block4E_3 {
    gap: 50px;

    width: 100%;
}

.block4E_4 {
    width: 325px;
    height: 300px;
    padding: 35px 45px;

    border-radius: 15px;
    background-color: var(--white-1-color);
    box-shadow: 
        0px 11.99619px 37.1195px 0px rgba(0, 0, 0, 0.07),
        0px 12.85947px 22.8252px 0px rgba(0, 0, 0, 0.04), 
        0px 14.27417px 40.6809px 0px rgba(0, 0, 0, 0.03),
        0px 27px 191px 0px rgba(0, 0, 0, 0.03);
}

.block4E_2_tg2 {
    width: 705px;
}

@media (max-width: 1024px) {
    .block4E_2 {
        width: 100%;
        padding: 0 20px;
    }

    .block4E_3 {
        gap: 42px;
    }

    .block4E_4 {
        width: 300px;
        height: 337px;
    }
}

@media (max-width: 720px) {
    .block4E_2_tg1 {
        font-size: 32px;
    }

    .block4E_2_tg2 {
        width: 100%;
    }

    .block4E_3 {
        gap: 20px;
    }

    .block4E_4 {
        width: 100%;
        height: auto;
    }
}