.hu_block4_1 {
    width: 100%;
    height: 842px;
    padding: 150px 0;

    background-image: url(../../../images/hu_block4_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hu_block4_2 {
    justify-content: space-between;

    width: 1166px;
}

.hu_block4_3 {
    width: 678px;
}

.hu_block4_tg1 {
    opacity: 1;
}

.hu_block4_button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 267px;
    height: 53px;

    text-decoration: none;
    color: var(--black-1-color);

    background-color: var(--white-1-color);
    border-radius: 45px;
}

.hu_block4_button:hover {
    background-color: rgba(250, 250, 250, 0.8);
}

.hu_block4_video {
    max-width: 377px;
    height: 400px;
    margin-top: 80px;
}

@media (max-width: 1024px) {
    .hu_block4_1 {
        height: 1180px;
        padding: 150px 100px;

        text-align: center;
    }

    .hu_block4_2 {
        align-items: center;
        justify-content: center;
        flex-direction: column;

        width: 100%;
    }

    .hu_block4_video {
        justify-content: center;
        order: -1;

        border-radius: 20px;
        overflow: hidden;
    }

    .hu_block4_tg2 {
        text-align: center;
    }

    .hu_block4_button {
        margin: 0 auto;
    }

    .hu_block4_button:hover {
        background-color: var(--white-1-color);
    }
}

@media (max-width: 720px) {
    .hu_block4_1 {
        height: 1142px;
        padding: 80px 20px;
    }

    .hu_block4_2 {
        width: 100%;
    }

    .hu_block4_3 {
        width: 100%;
    }

    .hu_block4_video {
        height: auto;
        margin-top: 40px;
    }

    .hu_block4_tg1 {
        font-size: 32px;

        text-align: center;
    }

    .hu_block4_tg2 {
        font-size: 20px;
        font-weight: normal;

        text-align: center;
    }

    .hu_block4_button {
        margin: 0 auto;
    }
}