.header.header_echo {
    position: absolute;

    padding-top: 36px;
    padding-bottom: 36px;
}

@media (max-width: 720px) {
    .block6E_tg1 {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        font-size: 32px;
    }
}

