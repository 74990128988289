.hu_block5_2 {
    width: 1166px;
}

.hu_block5_3 {
    column-gap: 50px;
}

.hu_block5_4 {
    width: 325px;
    height: 270px;
    padding: 35px 45px;

    background-color: var(--white-1-color);
    border-radius: 15px;

    box-shadow: var(--shadow);
}

.hu_block5_icon {
    width: 55px;
    height: 55px;
}

@media (max-width: 1024px) {
    .hu_block5_2 {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 720px) {
    .hu_block5_tg1 {
        font-size: 32px;
    }

    .hu_block5_3 {
        flex-direction: column;
        row-gap: 40px;
    }

    .hu_block5_4 {
        width: 100%;
        height: auto;
    }
}