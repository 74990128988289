:root {
    --background-1-color: rgba(242, 248, 247, 1);
    --background-echo: rgba(255, 248, 236, 1);
    --button-default-color: linear-gradient(
      91deg,
      var(--accent-1-color) -0.59%,
      var(--accent-second-1-color) 108.98%
    );
    --button-hover-color: linear-gradient(
        0deg,
        var(--black-02-color) 0%,
        var(--black-02-color) 100%
      ),
      linear-gradient(
        91deg,
        var(--accent-1-color) 0.94%,
        var(--accent-second-1-color) 100%
      );
    --main-1-color: rgba(44, 137, 154, 1);
    --main-05-color: rgba(44, 137, 154, 0.5);
    --second-1-color: rgba(74, 179, 189, 1);
    --black-1-color: rgba(0, 0, 0, 1);
    --black-02-color: rgba(0, 0, 0, 0.2);
    --black-06-color: rgba(0, 0, 0, 0.6);
    --black-09-color: rgba(0, 0, 0, 0.9);
    --gray10-1-color: rgba(26, 26, 26, 1);
    --gray20-1-color: rgba(51, 51, 51, 1);
    --gray30-1-color: rgba(132, 132, 132, 1);
    --accent-1-color: rgba(247, 195, 103, 1);
    --accent-second-1-color: rgba(240, 135, 70, 1);
    --white-1-color: rgba(255, 255, 255, 1);
  }
  
  html,
  body {
    user-select: none;
  }
  
  img {
    pointer-events: none;
  }
  
  .header {
    position: absolute;
    z-index: 100;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    box-sizing: border-box;
    width: 100%;
    padding-left: 75px;
    padding-right: 75px;
  
    transition: all 0.3s;
  }
  
  .header_button {
    position: relative;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 175px;
    height: 41px;
  
    color: var(--white-1-color);
    text-decoration: none;
  
    border-radius: 62px;
    background-color: var(--main-1-color);
  }
  
  .header_button:hover,
  .header_button:active {
    background-color: var(--second-1-color);
  }
  
  .block2_2 {
    position: relative;
  
    width: 1087px;
  }
  
  .scroll {
    display: flex;
    padding: 20px;
  
    overflow-x: auto;
    overflow-y: clip;
  
    transition: all 1.5s;
  
    padding-bottom: 20px;
    scrollbar-width: 0;
    clip-path: inset(0 0 20px 0);
  }
  
  .scroll::-webkit-scrollbar {
    -webkit-appearance: none;
  
    width: 0;
    height: 0;
  }
  
  .add-child {
    flex-shrink: 0;
  }
  
  .block3_icon {
    width: 55px;
    height: 55px;
  }
  
  .block5_image {
    width: inherit;
    object-fit: cover;
  }
  
  .block6_button {
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 528px;
    height: 51px;
  
    text-decoration: none;
  
    border-radius: 79px;
    background-image: var(--button-default-color);
  }
  
  .block6_button:hover,
  .block6_button:active {
    background-image: var(--button-hover-color);
  }
  
  .block6_button::after {
    position: absolute;
    top: -17px;
    left: -53px;
  
    content: url("../../images/shine.svg");
  }
  
  .block6_button::before {
    position: absolute;
    top: -20px;
    right: -53px;
  
    content: url("../../images/shine.svg");
    transform: rotate(0.5turn);
  }
  
  @media (max-width: 1024px) {
    .header {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .block2_1 {
      padding-left: 20px;
    }
  }
  
  @media (max-width: 720px) {
    .header {
      justify-content: center;
  
      position: absolute;
      padding: 0 20px !important;
  
      background-color: transparent !important;
    }
  
    .header_button {
      display: none;
    }
  
    .scroll {
      padding-bottom: 30px;
      clip-path: inset(0 0 20px 0);
    }
  
    .block2_title {
      font-size: 18px;
    }
  
    .block2_subtitle {
      font-size: 14px;
    }
  
    .block6_button {
      width: calc(100% - 40px);
      height: 68px;
      padding: 0 30px;
      box-sizing: border-box;
    }
  
    .block6_button::after,
    .block6_button::before {
      display: none;
    }
  }
    