.tg {
    margin: 0;
    padding: 0;

    font-family: 'DM Sans';
    font-style: normal;
    line-height: normal;
    font-weight: 400;

    white-space: pre-wrap;
}

.variant_h1 {
    font-size: 60px;
    font-weight: 500;
    line-height: 1.05; 
}

.variant_h1_2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 1.05; 
}

.variant_h2 {
    font-size: 48px;
    font-weight: 500;
}

.variant_h2_2 {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.6px;
    opacity: 0.5;
}

.variant_h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.05; 
}

.variant_h3_2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.05;
}

.variant_h3_3 {
    font-size: 32px;
    font-weight: 500;
}

.variant_h4 {
    font-size: 24px;
    font-weight: 600;
}

.variant_h5 {
    font-size: 16px;
    font-weight: 700;
}

.variant_h6 {
    font-size: 12px;
}

.variant_textLBold {
    font-size: 24px;
    font-weight: 700;
}

.variant_textM {
    font-size: 22px;
}

.variant_textMBold {
    font-size: 22px;
    font-weight: 700;
}

.variant_textS {
    font-size: 20px;
}

.variant_textS_600 {
    font-size: 20px;
    font-weight: 600;
}

.variant_textXS {
    font-size: 16px;
}

.variant_textXS_2 {
    font-size: 15px;
}

.variant_textXXS {
    font-size: 14px;
}

.color_main_1 {
    color: var(--main-1-color);
}

.color_main_05 {
    color: var(--main-05-color);
}

.color_black_1 {
    color: var(--black-1-color);
}

.color_black_06 {
    color: var(--black-06-color);
}

.color_black_09 {
    color: var(--black-09-color);
}

.color_gray10_1 {
    color: var(--gray10-1-color);
}

.color_gray20_1 {
    color: var(--gray20-1-color);
}

.color_gray30_1 {
    color: var(--gray30-1-color);
}

.color_accent_2 {
    color: var(--accent-second-1-color);
}

@media (max-width: 1024px) {
    .variant_h2 {
        font-size: 32px;
    }
}

@media (max-width: 720px) {
    .tg {
        white-space: unset;
    }
}